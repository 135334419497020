import React, { useState } from 'react';
import { useWeb3Authentication } from '@/context/web3AuthContext';
import { ProfilePopup } from './profile';
import { AuthButton } from './authButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

export const NavbarProfileButton: React.FC = () => {
  const { userData } = useWeb3Authentication();
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => setShowPopup(!showPopup);

  if (!userData) {
    return <AuthButton />;
  }

  return (
    <div className="relative">
      <button
        className="flex items-center space-x-2 p-2 rounded-full hover:bg-gray-900 transition-colors duration-200"
        onClick={togglePopup}
      >
        <div className="w-8 h-8 rounded-full bg-gray-700 flex items-center justify-center">
          <FontAwesomeIcon icon={faUser} className="text-white bg-transparent text-lg" />
        </div>
        <span className="text-sm font-medium text-white">
          {userData.customUserData.name}
        </span>
      </button>
      {showPopup && <ProfilePopup onClose={togglePopup} />}
    </div>
  );
};