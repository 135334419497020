'use client';

import React, { useState, useCallback, lazy, Suspense } from 'react';
import { useRouter } from 'next/navigation';
import Image from 'next/image';
import Link from 'next/link';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid';
import { Button } from '@/components/ui/button';
import { useWeb3Authentication } from '@/context/web3AuthContext';
import { Rubik } from 'next/font/google';
import TtLogo from '@/assets/images/tt-logo.png';
import ShoppingCartIcon from '@/components/cartSidebar/shoppingCartIcon/shoppingCartIcon';
import { usePathname } from 'next/navigation';
const rubik = Rubik({ subsets: ['latin'], style: ['normal', 'italic'] });
import { Dispatch, SetStateAction } from 'react';
import { motion } from 'framer-motion';
import { useMemo } from 'react';
import { AuthButton } from '@/components/userProfile/authButton';
import { NavbarProfileButton } from '@/components/userProfile/navButton';
import { FaInstagram, FaTwitter, FaLinkedin, FaYoutube } from 'react-icons/fa';

const RegistrationModal = lazy(() => import('@/components/registrationModal/RegistrationModal'));

const WithSubnavigation: React.FC = () => {
  const { login, logout, isLoading, userData, web3Auth } = useWeb3Authentication();
  const [isLoginStarted, setIsLoginStarted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const router = useRouter();
  const pathName = usePathname();
  

  const handleLogin = useCallback(async (pathname?: string) => {
    if (!web3Auth) {
      console.log('Web3Auth not initialized');
      return;
    }

    try {
      setIsLoginStarted(true);
      const newUserData = await login();

      if (newUserData?.registered) {
        setIsLoginStarted(false);
        router.push(pathname || '/');
      } else {
        setShowRegistrationModal(true);
      }
    } catch (error: any) {
      console.error('Login failed:', error);
      alert(`Login failed: ${error.message}`);
    } finally {
      setIsLoginStarted(false);
    }
  }, [web3Auth, login, router]);

  const handleLogout = useCallback(async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Logout failed:', error);
    }
  }, [logout]);

  if (pathName == "/") {
    return null;
  }
  
  const isExplorePageOrSubpage = pathName.startsWith('/fan/explore');
  const isFanPage = pathName.includes('fan');
  const navbarClasses = `px-2 z-50 flex w-full flex-col justify-center ${isExplorePageOrSubpage ? 'bg-transparent' : 'bg-black border-b border-white'} ${rubik.className}`;

  return (
    <div className={navbarClasses}>
      <div className="flex w-full max-w-[1400px] items-center justify-between py-2 mx-auto">
        <button
          className="md:hidden p-2"
          onClick={() => setIsOpen(!isOpen)}
          aria-controls="mobile-menu"
          aria-expanded={isOpen ? "true" : "false"}
        >
          {isOpen ? <XMarkIcon className="h-5 w-5" /> : <Bars3Icon className="h-5 w-5" />}
        </button>

        <div className="flex items-center justify-between w-full md:w-auto md:mx-0 md:mr-auto">
          <a href="/" className="flex items-center mx-auto">
            <Image src={TtLogo.src} alt="Logo" className="w-[150px]" width={200} height={200} />
          </a>
          {!isOpen && (
            <div className="md:hidden">
              <ShoppingCartIcon />
            </div>
          )}
        </div>

        <div className="md:hidden opacity-0">
          <Bars3Icon className="h-5 w-5" />
        </div>
        <div className="hidden w-full flex-row gap-4 md:flex md:justify-between min-h-12">
          <div className="flex w-full max-w-[520px] flex-row items-stretch gap-4">
            <DesktopNav currentPath={pathName} loggedIn={userData && userData.registered} userData={userData} handleLogin={handleLogin} setIsLoginStarted={setIsLoginStarted} />
          </div>

          {!isFanPage && (
            <div className="hidden md:flex items-center gap-6">
              <div className="flex items-center space-x-4">
                <a href="https://linkedin.com/company/ticket-tree" target="_blank" rel="noopener noreferrer">
                  <FaLinkedin size={20} className="text-white hover:text-[#2bb673]" />
                </a>
                <a href="https://www.instagram.com/tickettree.us/" target="_blank" rel="noopener noreferrer">
                  <FaInstagram size={20} className="text-white hover:text-[#2bb673]" />
                </a>
                <a href="https://twitter.com/tickettreeus" target="_blank" rel="noopener noreferrer">
                  <FaTwitter size={20} className="text-white hover:text-[#2bb673]" />
                </a>
                <a href="https://www.youtube.com/@tick3ttree" target="_blank" rel="noopener noreferrer">
                  <FaYoutube size={20} className="text-white hover:text-[#2bb673]" />
                </a>
              </div>
            </div>
          )}

          {isFanPage && (
            <div className="hidden items-center gap-6 md:flex">
              <ShoppingCartIcon />
              {userData ? (
                userData.registered ? (
                  <NavbarProfileButton />
                ) : null
              ) : (
                <AuthButton />
              )}
            </div>
          )}
        </div>
      </div>

      {isOpen && (
        <MobileNav 
          userData={userData} 
          setIsOpen={setIsOpen} 
          loggedIn={userData && userData.registered} 
          handleLogin={handleLogin} 
          handleLogout={handleLogout} 
          showRegistrationModal={showRegistrationModal} 
          setShowRegistrationModal={setShowRegistrationModal} 
          currentPath={pathName}
        />
      )}
      <Suspense fallback={<div>Loading...</div>}>
        {userData && !userData.registered && (
          <RegistrationModal 
            isOpen={showRegistrationModal} 
            onClose={() => setShowRegistrationModal(false)} 
          />
        )}
      </Suspense>
    </div>
  );
};

interface DesktopNavProps {
  currentPath: string;
  loggedIn: boolean;
  userData: any | undefined;
  handleLogin: (pathname?: string) => Promise<void>;
  setIsLoginStarted: Dispatch<SetStateAction<boolean>>;
}

const DesktopNav = React.memo(({ currentPath, loggedIn, userData, handleLogin, setIsLoginStarted }: DesktopNavProps) => {
  const router = useRouter();

  const handleNavigation = async (navItem: NavItem, e: React.MouseEvent) => {
    e.preventDefault();
    console.log(`Attempting to navigate to: ${navItem.href}`);

    if (navItem.href === "/fan/my-tickets" && !loggedIn) {
      setIsLoginStarted(true);
      await handleLogin(navItem.href);
      if (userData && userData.registered) {
        router.push(navItem.href);
      }
    } else {
      router.push(navItem.href);
    }
  };

  let NAV_ITEMS = HOST_NAV_ITEMS;
  if (currentPath.includes('host')) {
    console.log('Using HOST_NAV_ITEMS');
    NAV_ITEMS = HOST_NAV_ITEMS;
  } else if (currentPath.includes('fan')) {
    console.log('Using FAN_NAV_ITEMS');
    NAV_ITEMS = FAN_NAV_ITEMS;
  }

  return (
    <div className={`flex ml-12 w-full gap-12 items-stretch bg-transparent ${rubik.className}`}>
      {NAV_ITEMS.map((navItem: NavItem) => (
        <Link
          href={navItem.href}
          key={navItem.label}
          onClick={(e) => handleNavigation(navItem, e)}
          className="flex flex-col justify-stretch position-relative text-white hover:text-tGreen whitespace-nowrap"
        >
          <div className="justify-self-center flex-1 flex items-center">
            {navItem.label}
          </div>

          {navItem.href === currentPath ? (
            <motion.div
              layoutId="main-nav"
              className="position-absolute b-0 mt-1 h-0.5 w-full bg-green-400"
            />
          ) : (
            <div className="position-absolute b-0 mt-1 h-0.5 w-full bg-transparent opacity-0 transition-opacity duration-300 hover:opacity-100" />
          )}
        </Link>
      ))}
    </div>
  );
});

DesktopNav.displayName = 'DesktopNav';

interface MobileNavProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  loggedIn: boolean;
  handleLogin: (pathname?: string) => Promise<void>;
  handleLogout: () => Promise<void>;
  userData: any | undefined;
  showRegistrationModal: boolean;
  setShowRegistrationModal: React.Dispatch<React.SetStateAction<boolean>>;
  currentPath: string;
}

const MobileNav: React.FC<MobileNavProps> = React.memo(({ 
  setIsOpen, 
  loggedIn, 
  handleLogin, 
  handleLogout, 
  userData, 
  showRegistrationModal, 
  setShowRegistrationModal, 
  currentPath 
}) => {

  return (
    <div className="bg-[rgba(10, 10, 10, 0.95)] fixed z-50 inset-0 flex w-full flex-col justify-center backdrop-blur-lg backdrop-filter">
      <MobileNavItem 
        setIsOpen={setIsOpen} 
        loggedIn={loggedIn} 
        handleLogin={handleLogin} 
        handleLogout={handleLogout} 
        userData={userData}
        currentPath={currentPath}
      />
    </div>
  );
});

MobileNav.displayName = 'MobileNav';

interface MobileNavItemProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  loggedIn: boolean;
  handleLogin: (pathname?: string) => Promise<void>;
  handleLogout: () => Promise<void>;
  userData: any | undefined;
  currentPath: string;
}

const MobileNavItem: React.FC<MobileNavItemProps> = React.memo(({ 
  setIsOpen, 
  loggedIn, 
  handleLogin, 
  handleLogout, 
  userData,
  currentPath
}) => {
  const router = useRouter();

  const handleNavigation = useCallback(async (navItem: { href: string }) => {
    if (navItem.href === "/my-tickets" && !loggedIn) {
      await handleLogin(navItem.href);
      if (userData?.registered) {
        router.push(navItem.href);
      }
    } else {
      router.push(navItem.href);
    }
    setIsOpen(false);
  }, [loggedIn, handleLogin, userData, router, setIsOpen]);

  let NAV_ITEMS = HOST_NAV_ITEMS;
  if (currentPath.includes('host')) {
    NAV_ITEMS = HOST_NAV_ITEMS;
  } else if (currentPath.includes('fan')) {
    NAV_ITEMS = FAN_NAV_ITEMS;
  }

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 z-10" onClick={() => setIsOpen(false)} />
      <div className="w-1/2 h-full md:hidden absolute top-0 left-0 z-20 bg-[#282828] border-r border-transparent rounded-lg rounded-l-none">
        <div className="flex justify-between p-4 mt-5">
          <Link href="https://tick3ttree.com/" className="flex items-center">
            <Image src={TtLogo.src} alt="Logo" className="w-[150px]" width={TtLogo.width} height={TtLogo.height} />
          </Link>
          <button onClick={() => setIsOpen(false)} className="text-[#b9b9b9] hover:text-gray-300">
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>
        <nav className="flex flex-col mt-0 font-medium rounded-lg">
          <ul>
            {NAV_ITEMS.map((item) => (
              <li key={item.label} className="flex items-center py-5 px-3">
                <button
                  className="text-tGrey hover:bg-tGreen hover:rounded px-2 w-full text-left"
                  onClick={() => handleNavigation(item)}
                >
                  {item.label}
                </button>
              </li>
            ))}
          </ul>
          <div className="border-t border-[#3d3d3d] w-4/5 mx-auto my-4" />
          {loggedIn ? (
            <Button onClick={handleLogout} variant="primary" className="uppercase bold mx-6 hover:bg-transparent hover:text-[#2bb673]">
              Sign out
            </Button>
          ) : (
            <Button onClick={() => handleLogin()} variant="primary" className="uppercase bold mx-6">
              Log In
            </Button>
          )}
        </nav>
      </div>
    </>
  );
});

MobileNavItem.displayName = 'MobileNavItem';

interface NavItem {
  label: string;
  href: string;
  subLabel?: string;
  children?: Array<NavItem>;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
}

// const NAV_ITEMS: Array<NavItem> = [
//   {
//     label: "Home",
//     href: "/",
//   },
//   {
//     label: "Explore",
//     href: "/explore",
//   },
//   {
//     label: "About",
//     href: "/about",
//   },
//   {
//     label: "Fan",
//     href: "/portfolio",
//   },
//   {
//     label: "Contact",
//     href: "/contact",
//   },
//   {
//     label: "My Tickets",
//     href: "/my-tickets",
//   },
// ];

const HOST_NAV_ITEMS: Array<NavItem> = [
  {
    label: "Home",
    href: "/host",
  },
  {
    label: "Get Started",
    href: "/host/start",
  },
];

const FAN_NAV_ITEMS: Array<NavItem> = [
  {
    label: "Explore",
    href: "/fan/explore",
  },
  //{
    // label: "Experience",
   // href: "/fan",
  // },
  {
    label: "My Tickets",
    href: '/fan/my-tickets',
  },
  {
    label: "Contact",
    href: "/fan/contact",
  },
];

export default WithSubnavigation;