import React from 'react';
import { useWeb3Authentication } from '@/context/web3AuthContext';
import { AuthButton } from './authButton';
import { XMarkIcon } from '@heroicons/react/24/solid';

interface ProfilePopupProps {
  onClose: () => void;
}

export const ProfilePopup: React.FC<ProfilePopupProps> = ({ onClose }) => {
  const { userData } = useWeb3Authentication();

  if (!userData) return null;

  const email = userData.customUserData?.email_address || 'No email available';

  return (
    <div className="absolute right-0 mt-2 bg-black rounded-sm shadow-xl z-50 w-72 h-50 ">

      <div className="p-6 relative h-full">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-white transition-colors duration-200"
        >
          <XMarkIcon className="h-6 w-6 mb-4" />
        </button>
        <div className="flex flex-col space-y-6 h-full mt-8">
          <p className="text-sm text-green-400 px-4 py-2 bg-green-900 rounded-full text-center overflow-hidden text-ellipsis">
            {email}
          </p>
          <AuthButton />
        </div>
      </div>
    </div>
  );

};