"use client"
import { ReactNode, useEffect } from 'react';
import { useRouter, usePathname } from 'next/navigation';
import { useWeb3Authentication } from '@/context/web3AuthContext';
import { useState } from 'react';

const publicRoutes = [
  '/',
  '/sign-in',
  '/sign-up',
  '/host/about',
  '/host/portfolio',
  '/host/start',
  '/fan/explore',
  '/contact',
  '/fan/checkout',
  '/fan/events/*',
  '/host/checkout',
  '/fan/my-tickets',
  '/fan/explore/*',
  '/host/explore/*',
  '/fan/contact',
  '/fan',
  '/host',
  '/404',
  '/api/v1/event/*',
  '/api/v1/events/*',
  '/api/v1/products',
  '/api/v1/stripe/webhook',
  '/communities',
];

// Function to check if a path matches a route pattern
const isPathMatchingRoute = (path: string, route: string): boolean => {
  if (route.endsWith('*')) {
    const routePrefix = route.slice(0, -1);
    return path.startsWith(routePrefix);
  }
  return path === route;
};

const ProtectedRoute = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const { isLoading, userData, web3Auth, reconnect, isLoggedIn } = useWeb3Authentication();
  const pathName = usePathname();
  const [isChecking, setIsChecking] = useState(true);

  const isPublicRoute = publicRoutes.some(route => isPathMatchingRoute(pathName, route));

  useEffect(() => {
    const checkAuth = async () => {
      if (!userData && web3Auth && isLoggedIn) {
        const reconnectedData = await reconnect();
        // console.log("Reconnected Data", reconnectedData);
        if (!reconnectedData) {
          router.replace('/');
        }
      }
      setIsChecking(false);
    };
    // console.log(userData, web3Auth, isPublicRoute);
    if (!isLoggedIn && !isPublicRoute) {
      router.replace('/');
    }
    checkAuth();

  }, [isLoading, userData, router, isPublicRoute]);

  return children;
};

export default ProtectedRoute;